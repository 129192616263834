import React, { Fragment, useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import {
  DetailText,
  RegionSelect,
  RowText,
  Status,
  TableView
} from "../../../../../component/index.jsx"
import BootstrapTable from "react-bootstrap-table-next"
import {
  activitySendToErp,
  getDetailSpraying,
  updateAreaActivityImage
} from "../../../../../redux/actions/AreaAction.jsx"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { checkValue, toMeter } from "../../../../helpers/TextHelper.js"
import { AREA, AREA_ACTIVITY_TYPE, GENERAL } from "../../../../../constants/InitTypeConstants.js"
import { ACTIVITY_ICONS, ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { Popup, PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import swal from "sweetalert"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../helpers/StringCollection.js"
import { ACTIVITY_STATUS_ENUM } from "../../../../../constants/StringConstant.js"
import { ErpNumber } from "../../../../../component/atoms/ErpNumber/ErpNumber.jsx"

export const SprayingDetail = ({
  match: {
    params: { status, id, activity_id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const formRef = useRef()
  const { t } = useTranslation()
  const { MasterData, General, Area } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [pesticide, setPesticide] = useState([])
  const [idDocument, setIdDocument] = useState(null)
  const [dataListMember, setDataListMember] = useState([])
  const [openPopupTableList, setOpenPopupTableList] = useState(false)

  const [showModalDialog, setShowModalDialog] = useState(false)
  const [showModalDialogRegion, setShowModalDialogRegion] = useState(false)
  const [initialValue] = useState({
    regional_id: ""
  })
  const [isValid, setIsValid] = useState(false)
  const [regionalValue, setRegionalValue] = useState("")

  const validationSchema = Yup.object().shape({
    regional_id: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi))
  })

  useEffect(() => {
    dispatch(getDetailSpraying(activity_id))
  }, [])

  useEffect(() => {
    if (Area.data) {
      setDetail(Area.data)
      setPesticide(Area.data?.pesticide_list)
    }
  }, [Area])

  useEffect(() => {
    if (detail.pic) {
      setDataListMember(detail?.pic)
    }
  }, [detail])

  function checkBeforePlating() {
    return detail.activity_type_enum === AREA_ACTIVITY_TYPE.PRE_PLANTING
  }

  const btnApprove = (
    <Button className={"btn btn-primary"} onClick={() => setShowModalDialogRegion(true)}>
      {t("button.send_to_finance")}
    </Button>
  )

  useEffect(() => {
    if (detail) {
      let newStatus
      if (status === "active") {
        newStatus = "teregistrasi"
      }
      if (detail?.is_confirmed_erp) {
        subHeader.setButton(null)
      } else {
        subHeader.setButton(btnApprove)
      }
      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: "Lahan"
        },
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: newStatus
        },
        {
          pathname: "/lahan/list/" + status + "/" + id,
          title: detail?.area_information?.area_name
        },
        {
          title: "Penyemprotan"
        }
      ])
    }
  }, [detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete === true) {
      if (General.actionInitType === GENERAL.UPLOAD) {
        const data = {
          id: detail?.activity_detail_response?.image_and_id_activity[idDocument]?.id,
          url: MasterData.urlResponseUpload
        }
        dispatch(updateAreaActivityImage(data))
      } else if (General.actionInitType === AREA.UPDATE_ACTIVITY_IMAGE) {
        dispatch(getDetailSpraying(activity_id))
      } else if (
        General.loading === false &&
        General.actionInitType === AREA.APPROVE_ACTIVITY_TO_ERP
      ) {
        setShowModalDialog(false)
        swal({
          icon: ILUSTRATION_CONSTANS.SUCCESS_FORM,
          text: t("messages.response.activity_approve_to_erp_success")
        })
        dispatch(getDetailSpraying(activity_id))
      }
    }
  }, [General])

  const titleActivity = (
    <ActivityDetailHeader
      icon={ACTIVITY_ICONS.IC_PENYEMPROTAN}
      bottomContent={
        <ErpNumber
          noFaktur={detail?.no_faktur}
          noOrderSj={detail?.no_order_sj}
          noOrderSpk={detail?.no_order_spk}
          noOrderErp={detail?.no_order_erp}
          status={detail?.activity_type_enum}
        />
      }
      title={`AKTIVITAS PENYEMPROTAN
          ${checkBeforePlating() ? " PRA-TANAM " : " "} KE-${detail?.activity_detail_response?.seq}
          ${detail?.package_name && !checkBeforePlating() ? `(${detail?.package_name})` : ""}`}
      rightContent={
        <Status
          text={detail?.activity_status_string}
          variant={
            detail?.activity_status_enum === ACTIVITY_STATUS_ENUM.APPROVED ? "completed" : "warning"
          }
        />
      }
    />
  )

  const jenisHama = (data) => {
    if (data) {
      let hama = data.map((item) => item.name)
      return hama.join(", ")
    }
  }

  function dataPic() {
    return (
      <>
        {
          <div>
            {dataListMember.map((item, i) => (
              <>
                {i < 2 && (
                  <Fragment>{`${item.name} (${item.member}) ${
                    checkCommaInDataPic(i, dataListMember.length) ? "," : ""
                  } `}</Fragment>
                )}
              </>
            ))}
            {dataListMember.length > 2 && (
              <span
                onClick={() => setOpenPopupTableList(true)}
                className={"text-success font-weight-bolder  underline-hover cursor-pointer"}
                style={{ fontSize: 12, textDecoration: "underline" }}>
                +{dataListMember.length - 2} Lainnya
              </span>
            )}
          </div>
        }
      </>
    )
  }

  function checkCommaInDataPic(i, length) {
    if (length > 1 && i === 0) {
      return true
    } else if (length === 1) {
      return false
    } else if (length > 2) {
      return true
    } else if (length === 2 && i === 1) {
      return false
    }
  }

  const detailActivitas = (
    <>
      <Card>
        <CardBody>
          <h4 className={"mb-6 text-uppercase"}>INFORMASI LAHAN</h4>
          <RowModule customColumnCss={"col-md-6 mb-2"}>
            <RowModule>
              <RowText label={"Lahan"} value={detail?.area_information?.area_name ?? "-"} />
            </RowModule>
            <RowModule>
              <RowText
                label={"Luas Lahan (Manual)"}
                value={
                  detail?.area_information?.area_land_manual
                    ? toMeter(detail?.area_information?.area_land_manual)
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Petani"}
                value={
                  detail?.area_information?.farmer_name
                    ? `${detail?.area_information?.farmer_name} (${detail?.area_information?.farmer_code})`
                    : "-"
                }
                hasIcon={detail?.area_information?.has_kur}
                icon={detail?.area_information?.bank_image}
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Luas Lahan (Polygon)"}
                value={
                  detail?.area_information?.area_land_polygon
                    ? toMeter(detail?.area_information?.area_land_polygon)
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Mitra"}
                value={
                  detail?.area_information?.mitra_name
                    ? `${detail?.area_information?.mitra_name} (${detail?.area_information?.mitra_code})`
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              {!checkBeforePlating() && (
                <RowText
                  label={"Jenis Tanaman"}
                  value={detail?.area_information?.plant_type_name ?? "-"}
                />
              )}
            </RowModule>
            <RowModule>
              <RowText
                label={"Lokasi Lahan"}
                value={detail?.area_information?.full_address ?? "-"}
              />
            </RowModule>
            <RowModule>
              {!checkBeforePlating() && (
                <RowText
                  label={"Varietas Tanaman"}
                  value={detail?.area_information?.variety_name ?? "-"}
                />
              )}
            </RowModule>
          </RowModule>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h4 className={"mb-6 mt-6 text-uppercase"}>Detail Aktivitas</h4>
          <RowModule customColumnCss={"col-md-6 mb-2"}>
            <RowModule>
              <div>
                <RowText
                  label={"Submitted by"}
                  value={detail?.activity_detail_response?.submitted_by ?? "-"}
                />
                <RowText
                  label={"Submitted time"}
                  value={
                    detail?.activity_detail_response?.submitted_date
                      ? timeStampToDate(
                          detail?.activity_detail_response?.submitted_date,
                          "dd-MM-yyyy HH:mm:ss"
                        )
                      : "-"
                  }
                />

                <RowText
                  label={"Tanggal Aktivitas Terjadwal"}
                  value={
                    detail?.scheduled_date
                      ? timeStampToDate(detail?.scheduled_date, "dd-MM-yyyy")
                      : "-"
                  }
                />
                <RowText
                  label={"Tanggal Aktivitas Dikerjakan"}
                  value={
                    detail?.activity_detail_response?.activity_date
                      ? timeStampToDate(
                          detail?.activity_detail_response?.activity_date,
                          "dd-MM-yyyy"
                        )
                      : "-"
                  }
                />
                {!checkBeforePlating() && (
                  <RowText
                    label={"HST"}
                    value={checkValue(detail?.activity_detail_response?.hst, "HST")}
                  />
                )}

                <RowText label={"Metode Penyemprotan"} value={detail?.activity_method ?? "-"} />
              </div>
            </RowModule>
            <RowModule>
              <div>
                <RowText label={"Nama Pilot"} value={detail.pic ? dataPic() : "-"} />
                <RowText
                  label={"Cairan Terpakai"}
                  value={checkValue(detail?.liquid_use, "Liter")}
                />
                <RowText
                  label={"Gudang Pestisida"}
                  value={
                    detail?.activity_detail_response?.warehouse
                      ? detail?.activity_detail_response?.warehouse
                      : "-"
                  }
                />
                <RowText
                  label={"Jenis Hama"}
                  value={detail?.pest_type ? jenisHama(detail?.pest_type) : "-"}
                />
                <RowText
                  label={"Luas Lahan Dikerjakan (Alsintan)"}
                  value={
                    detail?.activity_detail_response?.worked_area
                      ? toMeter(detail?.activity_detail_response?.worked_area)
                      : "-"
                  }
                />

                <RowText
                  label={"Alasan"}
                  value={checkValue(detail?.activity_detail_response?.reason)}
                />
              </div>
            </RowModule>
          </RowModule>

          <div className={"my-10"}>
            <h4 className={"mb-6"}>CATATAN</h4>
            <div className={"row mb-4"}>
              {detail?.activity_detail_response?.image_and_id_activity &&
                detail?.activity_detail_response?.image_and_id_activity.map((item, index) => {
                  return (
                    <div className={"col-md-3"} key={index}>
                      <DetailText
                        type="image"
                        value={item.url}
                        indexKey={index}
                        menu={AREA.ACTIVITY_SPRAYING}
                        setIdDocument={setIdDocument}
                      />
                    </div>
                  )
                })}
            </div>
            <span className="font-weight-bold text-dark-50 font-size-sm">
              {!detail?.activity_detail_response?.note
                ? "-"
                : detail?.activity_detail_response?.note}
            </span>
          </div>
        </CardBody>
      </Card>
    </>
  )

  const uiDose = (e) => {
    return (
      <>
        {e?.dose ?? "-"} {e?.unit_doze ?? ""}
      </>
    )
  }

  const uiQtt = (e) => {
    return (
      <>
        {e?.quantity ?? "-"} {e?.unit_used ?? ""}
      </>
    )
  }

  const columns = [
    {
      dataField: "pesticide_name",
      text: "Nama Pestisida",
      headerClasses: "bg-gray"
    },
    {
      dataField: "owner_enum_string",
      text: "Kepemilikan Pestisida",
      headerClasses: "bg-gray"
    },
    {
      dataField: "dose",
      text: "Dosis",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDose
      }
    },
    {
      dataField: "quantity",
      text: "Pakai",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiQtt
      }
    }
  ]

  const columnsPic = [
    {
      dataField: "name",
      text: "NAMA TIM",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      headerStyle: () => {
        return { width: "25%" }
      }
    },
    {
      dataField: "member",
      text: "ANGGOTA"
    }
  ]

  const pesticideDetail = (
    <Card>
      <CardBody>
        <div className={"pesticideDetail"}>
          <h4 className={"mb-6"}>RINCIAN PESTISIDA</h4>
          <div className={"border rounded table-small-padding"}>
            <BootstrapTable
              condensed
              bordered={false}
              columns={columns}
              keyField={"pesticide_name"}
              data={pesticide}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )

  function uiName(e) {
    return <div className={"font-weight-bolder"}>{e?.name ?? "-"}</div>
  }

  function bodyPopupTable() {
    return (
      <div className={"table_list_popup_schedule table_hidden_bottom"}>
        <TableView
          dataTable={dataListMember}
          loadingGetData={false}
          columnProperties={columnsPic}
        />
      </div>
    )
  }

  return (
    <>
      <Popup
        contentClassName={"modal-main modal_detail_team_customs"}
        show={openPopupTableList}
        titleCustom={<h1>Anggota Tim</h1>}
        closeButton={true}
        showButton={false}
        onClick={() => setOpenPopupTableList(false)}
        body={bodyPopupTable()}
        size={"lg"}
        onCancel={() => {
          setOpenPopupTableList(false)
        }}
      />
      <PopupQuestion
        onCancel={() => setShowModalDialogRegion(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_select_regional")}
        onOk={() => {
          setShowModalDialogRegion(false)
          setShowModalDialog(true)
        }}
        disable={!isValid}
        show={showModalDialogRegion}
        bodyCustom={
          <Formik
            enableReinitialize
            initialValues={initialValue}
            innerRef={formRef}
            validationSchema={validationSchema}>
            {() => (
              <Form id={"formRegion"} className={"form"}>
                <div className={"mt-4"}>
                  <RegionSelect setIsValid={setIsValid} setRegionalValue={setRegionalValue} />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
      <PopupQuestion
        onCancel={() => setShowModalDialog(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_send_to_erp")}
        onOk={() => {
          dispatch(
            activitySendToErp(activity_id, {
              regional_id: regionalValue
            })
          )
          setShowModalDialog(false)
        }}
        show={showModalDialog}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {t("messages.confirmation.activity_approve_to_erp_confirmation")}
            </p>
          </RowModule>
        }
      />
      <div className={"w-100 d-flex flex-column"} style={{ gap: 12 }}>
        {titleActivity}
        {detailActivitas}
        {pesticide && pesticideDetail}
      </div>
    </>
  )
}
