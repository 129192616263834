import { useTranslation } from "react-i18next"
import {
  BadgeStatus,
  EmptyCard,
  TableSkeleton,
  TableView
} from "../../../../../component/index.jsx"
import React from "react"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../_metronic/_helpers/index.js"
import { checkValue, convertDotToComma } from "../../../../helpers/TextHelper.js"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import {
  DATE_TYPE_ENUM,
  INSPECTION_STATUS_ENUM,
  INSPECTION_STATUS_STRING
} from "../../../../../constants/StringConstant.js"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { Card } from "react-bootstrap"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"

export const BlockTable = ({ data, changePage, general, isFilter }) => {
  const { t } = useTranslation()

  function uiCode(e) {
    return (
      <>
        <div className={"text-dark font-weight-bolder  font-size-lg"}>
          {checkValue(e?.block_code)}
        </div>
        <div className={"d-flex"}>
          <div>{checkValue(e?.sub_district_name)}</div>
        </div>
      </>
    )
  }

  function uiMitra(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {checkValue(e?.mitra_name)}
        </div>
        <div>{checkValue(e?.mitra_phone)}</div>
      </div>
    )
  }

  function uiArea(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {convertDotToComma(e?.land_area_ha, "Ha")}
        </div>
        <div>{checkValue(e?.total_area, "Lahan")}</div>
      </div>
    )
  }

  function uiVariety(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {checkValue(e?.variety)}
        </div>
        <div>{checkValue(e?.hst, "HST")}</div>
      </div>
    )
  }

  function uiLastInspection(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {checkValue(e?.last_activity)}
        </div>
        <div>
          {checkValue(timeStampToDate(e?.last_activity_date, DATE_TYPE_ENUM.ISO_DATE_STRAP))}
        </div>
      </div>
    )
  }

  function uiInspectionStatus(e) {
    return (
      <div>
        {e?.status && (
          <BadgeStatus
            className={"w-130px"}
            variant={e?.status === INSPECTION_STATUS_ENUM.PASS ? "success" : "danger"}
            status={INSPECTION_STATUS_STRING[e?.status]}
          />
        )}
      </div>
    )
  }
  const columns = [
    {
      dataField: "code",
      text: t("table_header.block_name"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      },
      headerStyle: () => {
        return { width: "15%" }
      },
      sortCaret: sortCaret
    },
    {
      dataField: "mitra",
      text: t("table_header.mitra"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      }
    },
    {
      dataField: "totalAreaLand",
      text: t("table_header.area"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiArea
      },
      sortCaret: sortCaret
    },
    {
      dataField: "varietas",
      text: t("table_header.varietas"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiVariety
      }
    },
    {
      dataField: "lastActivityDate",
      text: t("table_header.last_inspection"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiLastInspection
      },
      sortCaret: sortCaret
    },
    {
      dataField: "status",
      text: t("table_header.inspection_status"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiInspectionStatus
      }
    }
  ]

  return (
    <>
      <Card>
        <CardBody>
          {general.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : data?.pageData?.length === 0 ? (
            <EmptyCard
              image={
                isFilter
                  ? ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_DATA
                  : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_DATA
              }
              body={
                isFilter
                  ? t("messages.response.response_not_found_data")
                  : t("messages.response.empty_state", { name: t("label.land_block") })
              }
              subtitle={isFilter ? t("messages.response.sub_response_not_found_data") : ""}
            />
          ) : (
            ""
          )}
          <TableView
            dataTable={data?.pageData ?? []}
            columnProperties={columns}
            loadingGetData={false}
            callbackAfterPageChange={changePage}
            currentPage={data?.page.currentPage}
            currentElement={data?.page.currentElement}
            totalCount={data?.page.totalCount}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            keyField={"id"}
          />
        </CardBody>
      </Card>
    </>
  )
}
