import { CardBody } from "../../../_metronic/_partials/controls/index.js"
import { Card } from "react-bootstrap"
import React from "react"
import { ICON_CONSTANS } from "../../../constants/ImageConstants.js"
import {
  ACTIVITY_TYPE_ENUM,
  ACTIVITY_TYPE_STRING,
  INSPECTION_STATUS_ENUM,
  INSPECTION_STATUS_STRING
} from "../../../constants/StringConstant.js"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import SVG from "react-inlinesvg"

export const CardQaPhase = ({ type, status }) => {
  const { t } = useTranslation()
  let badgeIcon
  // let label

  switch (type) {
    case ACTIVITY_TYPE_ENUM.PRELIMINARY:
      badgeIcon = ICON_CONSTANS.PHASE.IC_PHASE_PRELIMINARY
      break
    case ACTIVITY_TYPE_ENUM.VEGETATIVE:
      badgeIcon = ICON_CONSTANS.PHASE.IC_PHASE_VEGETATIVE
      break
    case ACTIVITY_TYPE_ENUM.FLOWERING:
      badgeIcon = ICON_CONSTANS.PHASE.IC_PHASE_FLOWERING
      break
    case ACTIVITY_TYPE_ENUM.RIPE:
      badgeIcon = ICON_CONSTANS.PHASE.IC_PHASE_RIPE
      break
    case ACTIVITY_TYPE_ENUM.HARVEST:
      badgeIcon = ICON_CONSTANS.PHASE.IC_PHASE_HARVEST
      break
    default:
      badgeIcon = ICON_CONSTANS.PHASE.IC_PHASE_PRELIMINARY
      break
  }
  return (
    <>
      <Card className={"mt-6 mb-6 p-4"} bg={"secondary"}>
        <CardBody className={"px-12 py-4 d-flex align-items-center"}>
          <div className={"font-size-lg mr-2"}>{t("label.qa_last_inspection") + ": "}</div>
          <SVG src={badgeIcon} />
          <div
            className={
              "font-size-lg font-weight-bolder mr-1"
            }>{`${ACTIVITY_TYPE_STRING[type]}`}</div>
          <div
            className={clsx(
              status === INSPECTION_STATUS_ENUM.PASS && "text-success",
              status === INSPECTION_STATUS_ENUM.NOT_PASS && "text-danger",
              "font-size-lg font-weight-bolder"
            )}>{`(${INSPECTION_STATUS_STRING[status]})`}</div>
        </CardBody>
      </Card>
    </>
  )
}
