import React, { useCallback, useEffect, useRef, useState } from "react"
import { BlockTable } from "./table/BlockTable.jsx"
import {
  getUrlParsingPageFilter,
  getUrlParsingSubmission
} from "../../../../config/EndpointCollection.js"
import { initialFilterTable } from "../../../../component/atoms/Tabel/index.jsx"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useTranslation } from "react-i18next"
import { Card } from "react-bootstrap"
import { CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import {
  CheckboxLabelButton,
  FilterCard,
  LocationMolecule,
  SelectInput
} from "../../../../component/index.jsx"
import {
  ACTIVITY_TYPE_ENUM,
  ACTIVITY_TYPE_STRING,
  INSPECTION_STATUS_ENUM,
  INSPECTION_STATUS_STRING
} from "../../../../constants/StringConstant.js"
import { getMitraFilterBlock } from "../../../../redux/actions/MitraAction.jsx"
import { getBlockList } from "../../../../redux/actions/AreaAction.jsx"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"
import { getVarietyFilterBlock } from "../../../../redux/actions/MasterDataAction.jsx"
import { useLocation } from "react-router"

export const ListLandBlock = ({ history }) => {
  const location = useLocation()
  const subHeader = useSubheader()
  const urlParams = useUrlParam()
  const formRef = useRef()
  const { t } = useTranslation()
  const userLogin = JSON.parse(localStorage.getItem("userLogin"))
  const { General, Mitra, MasterData, Area } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState("")
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [isActiveFilter, setIsActiveFilter] = useState(false)
  const [mitraList, setMitraList] = useState([])
  const [mitraFilterValue, setMitraFilterValue] = useState("")
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [resetLocation, setResetLocation] = useState(false)
  const [typeValue, setTypeValue] = useState([])
  const [selectedRegional, setSelectedRegional] = useState([])
  const [varietyValue, setVarietyValue] = useState("")
  const [listRegional, setListRegional] = useState([])
  const [selectedRegionalUser, setSelectedRegionalUser] = useState()
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [varietyType, setVarietyType] = useState([])
  const [isType, setIsType] = useState([])
  const [statusValue, setStatusValue] = useState([])
  const [isStatus, setIsStatus] = useState([])
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const [initialValue, setInitialValue] = useState({
    ...initialFilterTable,
    province_id: "",
    regency_id: "",
    district_id: "",
    sub_district_id: "",
    mitra_id: "",
    variety_id: "",
    status: "",
    activity_type: ""
  })

  useEffect(() => {
    if (MasterData?.listRegionalSelected) {
      setListRegional(
        MasterData.listRegionalSelected.map((item) => {
          return {
            label: item?.name + " (" + item?.code + ")",
            value: item?.id
          }
        })
      )
    }
  }, [MasterData])

  useEffect(() => {
    if (MasterData?.varietyList) {
      setVarietyType(
        MasterData?.varietyList.map((value) => ({
          label: value?.name,
          value: value?.id
        }))
      )
    }
  }, [MasterData?.varietyList])

  useEffect(() => {
    subHeader.setTitle(t(`label.land_block`))
    subHeader.setButton(null)
    dispatch(getVarietyFilterBlock())
    dispatch(getMitraFilterBlock())
    if (userLogin?.regional_id) {
      setSelectedRegionalUser(userLogin?.regional_id)
    }
  }, [])

  useEffect(() => {
    setIsType(
      Object.keys(ACTIVITY_TYPE_ENUM).map((key) => ({
        label: ACTIVITY_TYPE_STRING[key],
        enum: ACTIVITY_TYPE_ENUM[key],
        checked: typeValue.findIndex((e) => e === ACTIVITY_TYPE_ENUM[key]) >= 0
      }))
    )
  }, [typeValue])

  useEffect(() => {
    setIsStatus(
      Object.keys(INSPECTION_STATUS_ENUM).map((key) => ({
        label: INSPECTION_STATUS_STRING[key],
        enum: INSPECTION_STATUS_ENUM[key],
        checked: statusValue.findIndex((e) => e === INSPECTION_STATUS_ENUM[key]) >= 0
      }))
    )
  }, [statusValue])

  useEffect(() => {
    if (Mitra?.data) {
      setMitraList(
        Mitra?.data.map((value) => ({
          label: value?.name,
          value: value?.id
        }))
      )
    }
  }, [Mitra.data])

  useEffect(() => {
    if (listRegional && selectedRegional) {
      const data = listRegional.find((e) => e?.value === selectedRegionalUser)
      if (data) {
        setSelectedRegional([data])
      }
    }
  }, [listRegional, selectedRegionalUser])

  useEffect(() => {
    if (
      formRef.current.values.province_id ||
      mitraFilterValue ||
      varietyValue ||
      typeValue.length > 0 ||
      statusValue.length > 0
    )
      setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [formRef.current, typeValue, mitraFilterValue, varietyValue, statusValue])

  useEffect(() => {
    checkQueryParamOnPageLoad()
  }, [queryStringObj])

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location?.search])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  const checkQueryParamOnPageLoad = () => {
    if (queryStringObj?.keyword) {
      setSearchValue(queryStringObj?.keyword)
      setIsActiveSearch(true)
    }
    if (
      queryStringObj?.mitra_id ||
      queryStringObj?.province_id ||
      queryStringObj?.regency_id ||
      queryStringObj?.district_id ||
      queryStringObj?.sub_district_id ||
      queryStringObj?.variety_id ||
      queryStringObj?.status ||
      queryStringObj?.activity_type
    ) {
      const data = {
        ...initialFilterTable,
        keyword: queryStringObj?.keyword,
        province_id: queryStringObj?.province_id,
        regency_id: queryStringObj?.regency_id,
        district_id: queryStringObj?.district_id,
        sub_district_id: queryStringObj?.sub_district_id,
        mitra_id: queryStringObj?.mitra_id,
        variety_id: queryStringObj?.variety_id,
        status: queryStringObj?.status,
        activity_type: queryStringObj?.activity_type
      }
      if (data) {
        setIsActiveFilter(true)
        setInitialValue(data)
        setMitraFilterValue(queryStringObj?.mitra_id)
        setVarietyValue(queryStringObj?.variety_id)
        if (data?.activity_type) {
          setTypeValue(data?.activity_type.split(","))
        }
        if (data?.status) {
          setStatusValue(data?.status.split(","))
        }
      }
    }
  }

  const checkFilterAndSearch = () => {
    if (isActiveSearch || isActiveFilter) {
      return true
    } else {
      return false
    }
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: searchValue
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          keyword: searchValue
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, searchValue, initialValue, location]
  )

  function fetchData(param) {
    return dispatch(getBlockList(param))
  }

  function checkRegionalSelected() {
    if (userLogin?.regional_id) {
      return userLogin?.regional_id
    } else {
      if (selectedRegional) {
        const data = selectedRegional.map((e) => {
          return e.value
        })
        if (data.length > 0) {
          return data.join(",")
        }
      }
    }
  }

  function handleType(data) {
    const { name, checked } = data.target

    if (checked) {
      setTypeValue([...typeValue, name])
    } else {
      let filteredStatus = typeValue.filter((item) => item !== name)
      setTypeValue(filteredStatus)
    }
  }

  function handleStatus(data) {
    const { name, checked } = data.target

    if (checked) {
      setStatusValue([...statusValue, name])
    } else {
      let filteredStatus = statusValue.filter((item) => item !== name)
      setStatusValue(filteredStatus)
    }
  }

  const onSearch = (values) => {
    setSearchValue(values?.keyword)
    setIsActiveSearch(true)
    let param = {
      ...initialValue,
      keyword: values?.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const handleResetSearch = () => {
    setSearchValue("")
    setIsActiveSearch(false)
    const param = {
      ...initialValue,
      keyword: ""
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ search: urlParam })
  }
  const handleOpenCloseFilter = (value) => {
    setIsShowFilter(value)
  }

  const onSubmitFilter = (e) => {
    setResetLocation(false)
    const { values, submited } = e
    if (submited) {
      setIsActiveFilter(true)
      setIsShowFilter(false)
      const paramData = {
        ...initialFilterTable,
        keyword: searchValue,
        mitra_id: values?.mitra_id,
        province_id: values?.province_id,
        regency_id: values?.regency_id,
        district_id: values?.district_id,
        sub_district_id: values?.sub_district_id,
        variety_id: values?.variety_id,
        status: statusValue,
        activity_type: typeValue
      }
      if (paramData) {
        setInitialValue(paramData)
        const urlParam = getUrlParsingPageFilter(paramData)
        history.push({ search: urlParam })
      }
    }
  }

  const onResetFilter = () => {
    setResetLocation(true)
    setIsShowFilter(false)
    setIsActiveFilter(false)
    setTypeValue([])
    setStatusValue([])
    const param = {
      ...initialFilterTable,
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      mitra_id: "",
      variety_id: "",
      status: "",
      activity_type: ""
    }
    setInitialValue(param)
    setMitraFilterValue("")
    const urlParam = getUrlParsingSubmission(param)
    history.push({ search: urlParam })
  }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.mitra")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="mitra_id"
              label={t("label.mitra_name")}
              editable={true}
              placeholder={t("placeholder.select_one")}
              options={mitraList}
              withoutFeedback={true}
              isClearable={true}
              value={mitraFilterValue}
              onChange={(e) => setMitraFilterValue(e)}
            />
          </RowModule>
        </CardBody>
      </Card>
      <LocationMolecule
        resetLocation={resetLocation}
        regionalId={checkRegionalSelected()}
        name="filter"
        withoutFeedback={true}
        isReadOnly={false}
        addressTitle={t("label.location")}
        removeHorizontalPadding={true}
      />
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.varietas")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="variety_id"
              onChange={(e) => setVarietyValue(e)}
              editable={true}
              label={t("label.variety_name")}
              placeholder={t("placeholder.select_one")}
              options={varietyType}
              withoutFeedback={true}
              clearNow={true}
              value={varietyValue}
              isClearable={true}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.last_inspection")} className="px-0" />
        <CardBody className="px-0">
          <CheckboxLabelButton
            formInitialValues={typeValue}
            isStatus={isType}
            name={"activity_type"}
            innerRef={formRef}
            handleChange={handleType}
          />
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.inspection_status")} className="px-0" />
        <CardBody className="px-0">
          <CheckboxLabelButton
            formInitialValues={statusValue}
            isStatus={isStatus}
            name={"status"}
            innerRef={formRef}
            handleChange={handleStatus}
          />
        </CardBody>
      </Card>
    </>
  )

  return (
    <div className={"d-flex flex-column"}>
      <FilterCard
        isActiveSearch={isActiveSearch}
        placeholder={t("placeholder.search_block_name")}
        onSubmitForm={onSearch}
        initialValues={{ keyword: searchValue }}
        name={"keyword"}
        innerRef={formRef}
        trigerReset={handleResetSearch}
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmitFilter}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={onResetFilter}
        showFilter={isShowFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={isActiveFilter}
        btnDisable={btnFilterDisable}
      />
      <BlockTable
        data={Area}
        changePage={changePage}
        history={history}
        general={General}
        isActiveSearch={isActiveSearch}
        initialFilterTable={initialFilterTable}
        isFilter={checkFilterAndSearch()}
      />
    </div>
  )
}
