import React, { Fragment } from "react"
import { Card } from "react-bootstrap"
import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls/index.js"
import { CardDetail, DetailText, RowText } from "../../../../../component/index.jsx"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"

import { GoogleMap, Polygon, useLoadScript } from "@react-google-maps/api"
import { coordinateCenterPolygon, GoogleMapKey } from "../../../../helpers/MapHelper.js"
import { useTranslation } from "react-i18next"
import { checkValue } from "../../../../helpers/TextHelper.js"
import { CardQaPhase } from "../../../../../component/atoms/CardQaPhase/CardQaPhase.jsx"

export const AreaTab = ({
  titleCardDetail,
  descriptionLeft,
  descriptionRight,
  flatIconAndText2Rows,
  documentLahan,
  coordinate,
  detail,
  zoom,
  titleImage = null,
  withDocument = true,
  rejectReason,
  reason,
  reasonTitle = null,
  setIdDocument,
  mapAreas = [],
  status,
  loadingMap
}) => {
  const { isLoaded } = useLoadScript({
    // Enter your own Google Maps API key
    googleMapsApiKey: GoogleMapKey()
  })
  const { t } = useTranslation()

  const poligonStyle = {
    fillColor: "lightblue",
    fillOpacity: 0.7,
    strokeColor: "white",
    strokeOpacity: 0.6,
    strokeWeight: 1,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }

  // map
  const containerStyle = {
    width: "100%",
    height: "380px"
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1 // optional, default to 1.
    }
  }

  const poligonStyleNew = (color) => {
    return {
      fillColor: "#" + color,
      fillOpacity: color === "FFFFFF" ? 0.5 : 0.7,
      strokeColor: "white",
      strokeOpacity: 1,
      strokeWeight: 0.4,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1
    }
  }

  const renderMap = () => {
    return (
      <>
        {rejectReason && (
          <RowModule key={"card-rejectReason"}>
            <Card className={"reject-list-card"}>
              <CardHeader title={"Alasan Penolakan"} className="mb-0 pb-2" />
              <CardBody>
                {!rejectReason ? null : rejectReason?.length === 0 ? (
                  <span>Alasan tidak ditemukan.</span>
                ) : (
                  <ul>
                    {rejectReason?.map((e, i) => (
                      <li key={i}>{e.enum_value}</li>
                    ))}
                  </ul>
                )}
              </CardBody>
            </Card>
          </RowModule>
        )}
        {reason && (
          <RowModule key={"card-reasonList"}>
            <Card className={"reason-list-card"}>
              <CardHeader title={reasonTitle} className="mb-0 pb-2" />
              <CardBody>
                {!reason ? null : Array.isArray(reason) && reason?.length > 0 ? (
                  <ul>
                    {reason?.map((e, i) => (
                      <li key={i}>{e.enum_value}</li>
                    ))}
                  </ul>
                ) : (
                  <ul>
                    <li>{reason}</li>
                  </ul>
                )}
              </CardBody>
            </Card>
          </RowModule>
        )}
        <RowModule key={"card-list"}>
          <CardDetail
            titleCardDetail={titleCardDetail}
            descriptionLeft={descriptionLeft}
            descriptionRight={descriptionRight}
            flatIconAndText2Rows={flatIconAndText2Rows}
            titleImage={titleImage}
            kurBNI={detail?.has_kur}
            kurBNIImageurl={detail?.bank_image}
          />
        </RowModule>

        {detail?.has_planting_season && (
          <RowModule key={"card-plantingSeason"}>
            <Card>
              <CardBody>
                <h1 className={"mb-5 pb-5"}>{t("label.planting_season_information")}</h1>
                {detail?.block_information && detail?.block_information.is_seedling && (
                  <div>
                    {detail?.block_information.block_activity && (
                      <CardQaPhase
                        type={detail?.block_information.block_activity}
                        status={detail?.block_information.block_activity_status}
                      />
                    )}
                    <Card className={"mt-6 mb-6 p-4"} bg={"secondary"}>
                      <CardBody className={"px-12 py-4"}>
                        <div className={"mb-5 pb-5 font-weight-bolder font-size-h5-lg"}>
                          {t("label.block_information")}
                        </div>
                        <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
                          <RowText
                            label={t("label.block_name")}
                            value={checkValue(detail?.block_information.block_name)}
                          />
                          <RowText
                            label={t("label.file_number")}
                            value={checkValue(detail?.block_information.doc_number)}
                          />
                          <RowText
                            label={t("label.doc_number")}
                            value={checkValue(detail?.block_information.request_number)}
                          />
                          <RowText
                            label={t("label.certification_number")}
                            value={checkValue(detail?.block_information.base_number)}
                          />
                        </RowModule>
                      </CardBody>
                    </Card>
                  </div>
                )}

                <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
                  <RowText label={t("label.plant_type")} value={checkValue(detail?.plant_name)} />
                  <RowText
                    label={t("label.harvest_date_prediction")}
                    value={checkValue(detail?.harvest_date)}
                  />
                  <RowText
                    label={t("label.plant_varieties")}
                    value={checkValue(detail?.plant_varieties_name)}
                  />
                  <RowText label={t("label.hst")} value={checkValue(detail?.hst)} />
                  <RowText
                    label={t("label.planting_date")}
                    value={checkValue(detail?.planting_date)}
                  />
                  <RowText
                    label={t("label.spraying_package")}
                    value={checkValue(detail?.package_name)}
                  />
                </RowModule>
              </CardBody>
            </Card>
          </RowModule>
        )}

        <RowModule key={"card-lokasiLahan"}>
          <Card className="card-custom">
            <CardHeader title={"LOKASI LAHAN"} />
            <CardBody>
              {loadingMap ? (
                <div
                  className={"d-flex justify-content-center align-items-center"}
                  style={containerStyle}>
                  <div className="spinner spinner-primary" />
                </div>
              ) : (
                <Fragment googleMapsApiKey="AIzaSyDWuPp3biF_NddtL2Q7Zh5ewx4qERnUvkE">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={coordinateCenterPolygon(coordinate)}
                    zoom={zoom}
                    mapTypeId={"hybrid"}>
                    {status === "active" || status === "pending" || status === "reject" ? (
                      status === "pending" && reason ? (
                        <Polygon paths={coordinate} options={poligonStyle} />
                      ) : (
                        mapAreas.map((place, index) => {
                          return (
                            <Polygon
                              key={index}
                              paths={place?.coordinates}
                              options={poligonStyleNew(place?.color)}
                            />
                          )
                        })
                      )
                    ) : (
                      <Polygon paths={coordinate} options={poligonStyle} />
                    )}
                  </GoogleMap>
                </Fragment>
              )}
            </CardBody>
          </Card>
          {withDocument && (
            <DetailText
              title={"BUKTI KEPEMILIKAN LAHAN"}
              type={"carousel"}
              value={documentLahan ? documentLahan.filter((item) => (item ? item : null)) : null}
              menu="AREA_DOCUMENT"
              responsiveCarousel={responsive}
              setIdDocument={setIdDocument}
            />
          )}
        </RowModule>
      </>
    )
  }

  return isLoaded ? renderMap() : null
}
